.dropzone {
  text-align: center;
  padding: 10px;
  width: 90%;
  margin: auto;
  height: 40vh;
}
.text-center{
    margin-top: 15vh;
}
